import { useGetCurrencyCloudStatus, useRequest } from "src/v2/api/hooks";
import { Bank, getBanks } from "src/v2/api/rest/account";
import {
  getAchRelationships,
  getBankInstitution,
} from "src/v2/api/rest/banking";
import { AchRelationshipWithBankInfo } from "../../pages/dashboard/funding/common/types";
import { getCountryInfos } from "src/v2/api/rest/onboarding";
import { useGetCurrencyCloudAccount } from "src/v2/api/hooks";
import { useMemo } from "react";
import {
  GetCurrencyCloudAccountResponse,
  GetCurrencyCloudStatusResponse,
} from "src/v2/api/rest/currencyCloud";
import { QueryObserverResult } from "react-query";

type UseGetAllRelationshipsProps = {
  accountID: string;
  countryOfTaxResidence: string;
  key?: string[];
};

export type UseGetAllRelationshipsReturn = {
  ach: {
    relationships: AchRelationshipWithBankInfo[];
    isLoading: boolean;
    refetch: () => Promise<
      QueryObserverResult<AchRelationshipWithBankInfo[], unknown>
    >;
  };
  wire: {
    relationships: Bank[];
    isLoading: boolean;
    refetch: () => Promise<QueryObserverResult<Bank[], unknown>>;
  };
  currencyCloud: {
    account: GetCurrencyCloudAccountResponse | undefined;
    status: GetCurrencyCloudStatusResponse | undefined;
    isLoading: boolean;
    isEnabled: boolean;
    refetchAccount: () => Promise<
      QueryObserverResult<GetCurrencyCloudAccountResponse, unknown>
    >;
    refetchStatus: () => Promise<
      QueryObserverResult<GetCurrencyCloudStatusResponse, unknown>
    >;
  };
};

export const useGetAllRelationships = ({
  accountID,
  countryOfTaxResidence,
  key = ["get-all-relationships"],
}: UseGetAllRelationshipsProps): UseGetAllRelationshipsReturn => {
  const ach = useRequest(
    [...key, "ach-relationships", accountID],
    async () => {
      const relationships = await getAchRelationships(accountID);

      // All this just for bank logos >:(
      return Promise.all(
        (relationships ?? []).map(async (relationship) => {
          const bankInstitution = await getBankInstitution(
            relationship.plaid_institution
          );

          const bankRelationship: AchRelationshipWithBankInfo = {
            ...bankInstitution,
            ...relationship,
            logo: `data:image/png;base64,${bankInstitution.logo}`,
          };

          return bankRelationship;
        })
      );
    },
    {
      enabled: !!accountID,
      refetchOnMount: "always",
    }
  );

  const banks = useRequest(
    [...key, "banks", accountID],
    () => getBanks({ accountID }),
    {
      enabled: !!accountID,
      refetchOnMount: "always",
    }
  );

  const { data: countryInfo } = useRequest("countryInfos", () =>
    getCountryInfos()
  );

  const isCurrencyCloudEnabled = useMemo(
    () => countryInfo?.[countryOfTaxResidence]?.currencycloud_enabled ?? false,
    [countryInfo, countryOfTaxResidence]
  );

  const ccStatus = useGetCurrencyCloudStatus({
    accountID,
    key: [...key, "cc-status", accountID],
    options: {
      enabled: !!accountID && isCurrencyCloudEnabled,
      refetchOnMount: "always",
    },
  });

  const ccAccount = useGetCurrencyCloudAccount({
    accountID,
    key: [...key, "cc-account", accountID],
    options: {
      enabled:
        !!accountID &&
        isCurrencyCloudEnabled &&
        // returns 500 server error when beneficiary does not exist
        ccStatus.data?.beneficiary_exists === "true",
      refetchOnMount: "always",
    },
  });

  return {
    ach: {
      relationships: ach.data || [],
      isLoading: ach.isLoading,
      refetch: ach.refetch,
    },
    wire: {
      relationships: banks.data || [],
      isLoading: banks.isLoading,
      refetch: banks.refetch,
    },
    currencyCloud: {
      account: ccAccount.data,
      status: ccStatus.data,
      isLoading: ccAccount.isLoading && ccStatus.isLoading,
      isEnabled: isCurrencyCloudEnabled,
      refetchAccount: ccAccount.refetch,
      refetchStatus: ccStatus.refetch,
    },
  };
};
